<template>
    <main>
        <div class="modal fade" id="changeCampusModal" data-backdrop="static" data-keyboard="false" role="dialog" aria-labelledby="changeCampusModal" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Seleccionar sede</h5>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="form-group col-12">
                                <label class="small mb-0">Seleccione la sede para continuar</label>
                                <v-select-sede v-model="campus_id" :class="{'is-invalid' : $v.campus_id.$error}"></v-select-sede>
                                <span v-if="!$v.campus_id.required && $v.campus_id.$dirty" class="text-danger">
                                    <i class="fas fa-exclamation-triangle"></i> Debe seleccionar una sede para continuar.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="logout()">Cerrar</button>
                        <button type="button" class="btn btn-primary btn-sm" @click="save()">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import VSelectSede from "@/components/common/VSelectSede.vue";
import Swal from "sweetalert2";
import loginService from "../../../services/loginService";
import $ from "jquery";
import {required} from "vuelidate/lib/validators";
export default {
    name: "selectCampus",
    components: {VSelectSede},
    data(){
        return {
            campus_id : ''
        }
    },
    validations (){
        return {
            campus_id:{required}
        }
    },
    mounted() {
        this.$store.commit('SET_LAYOUT', 'index-layout');
        this.$nextTick(() => $("#changeCampusModal").modal('show'));
    },
    methods: {
        async save(){
            try{
                this.$v.$touch();
                if(this.$v.$invalid) return;

                this.LoaderSpinnerShow();
                await loginService.changeCampus({campus_id : this.campus_id});
                this.$nextTick(() => $("#changeCampusModal").modal('hide'));
                this.LoaderSpinnerHide();
                Swal.fire('Exito', '', 'success');
                this.$router.push({ name: 'dashboard' });
            }catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Swal.fire('Ups', 'ha ocurrido un error al procesar la solicitud', 'error');
            }
        },
        async logout() {
            try {
                this.LoaderSpinnerShow();
                await this.$store.dispatch("logout");
                this.LoaderSpinnerHide();
                this.$store.commit('SET_LAYOUT', 'login-layout');
                await this.$router.push({path: '/login'});
            } catch (error) {
                this.LoaderSpinnerHide();
                console.error(error);
            }
        }
    },
}
</script>

<style scoped>

</style>
